import { defineStore } from 'pinia';
import axiosAuth from '@/utils/AxiosAuth';
import { ref } from 'vue';
import { useRuntimeStore } from './runtimeStore';
import {notify} from "notiwind";

export const useCompany = defineStore('companyStore', () => {
  const root = useRuntimeStore().$state.apiRoot
  const companiesList = ref([]);
  const globalSearchCompany = ref([]);
  const total_count = ref('')
  const selected_company_id = ref('')
  const isLoading = ref(true)
  const companyProfileData = ref([])
  const search_text = ref('')
  const newCompanyData = ref({
    name : ''
  })
  const newAdministratorData = ref({
    roles: ['admin','arborist'],
    title: 'Admin',
    name: {first: '', last: ''},
    email: '',
    phone: ''
  })

  const getCompaniesList = async (data) => {

    if (search_text.value) {
      data['search_text'] = search_text.value.trim();
    }
    try {
      const res = await axiosAuth.get(`${root}/company/`, {params: data});
      isLoading.value = false;
      if (data['origin'] === "global_search"){
        globalSearchCompany.value = res.data.company;
      }else {
        companiesList.value = res.data.company;
        total_count.value = res.data.count
      }
    } catch (error) {
      isLoading.value = false;
      console.error('Error fetching companies list:', error);
      notify(
          {
            group: "error",
            title: 'Company',
            text: 'Error fetching company list',
          },
          2000
      );
    }
  };


  const createCompany = async (company,administrator) => {
    try {
      const companyId = ref(null);

      await axiosAuth.post(`${root}/company/`,{company:company, profile: administrator}).then((res) =>{
        companiesList.value.unshift(res.data)
        newCompanyData.value.name=''
        newAdministratorData.value.name['first'] = ''
        newAdministratorData.value.name['last'] = ''
        newAdministratorData.value.email = ''
        newAdministratorData.value.phone = ''
           companyId.value = res.data.id;

        notify({
          group: 'success',
          title: 'Company Created',
          text: 'Company is created Successfully'
        }, 2000)
      })
    return companyId.value;

    } catch (error) {
      console.error(error);
      notify(
          {
            group: "error",
            title: 'Company',
            text: error?.response?.data?.message,
          },
          2000
      );
    }
  }
  const updateCompany = async (id) => {
    try {
      const res = await axiosAuth.put(`${root}/company/${id}`)
    } catch (error) {
      console.error('Error updating company', error);
    }
  }

  const getCompanyDataById = async (id) => {
    try {
      const res = await axiosAuth
          .get(`${root}/profile/company_data`, { params:  {company_id: id }})
          .then((res) => {
            companyProfileData.value = res.data
            selected_company_id.value = id
          })}
    catch (error) {
      isLoading.value=false
      console.error('Error fetching companies list:', error);
      notify(
          {
            group: "error",
            title: 'Company',
            text: 'Error fetching company data',
          },
          2000
      );
    }
  };
  const value = {
    companiesList,
    globalSearchCompany,
    isLoading,
    companyProfileData,
    search_text,
    newCompanyData,
    newAdministratorData,
    total_count,
    selected_company_id
  };

  const actions = {
    getCompaniesList,
    createCompany,
    getCompanyDataById,
    updateCompany
  };

  return {
    ...value,
    ...actions,
  };
});
